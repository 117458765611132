<template>
  <div :class="navbarContentContainerClass">
    <b-navbar-nav class="flex-nowrap d-flex mr-2">
      <b-nav-item-dropdown
        no-caret
        v-if="
          [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(
            get(getLoggedInUser, 'role'),
          ) && !isMediumScreen
        "
      >
        <template slot="button-content">
          <md-icon>apps</md-icon>
        </template>

        <b-dropdown-header tag="div" class="form-label"> Switch to </b-dropdown-header>

        <b-dropdown-item
          v-if="
            [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(
              get(getLoggedInUser, 'role'),
            )
          "
          :href="externalUrl"
          target="_blank"
          exact
        >
          <md-icon>open_in_new</md-icon> Transition Enroll
        </b-dropdown-item>

        <b-dropdown-item
          @click.prevent="openStdModal"
          v-if="[USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(get(getLoggedInUser, 'role'))"
        >
          <md-icon>web</md-icon> Transition Connect
        </b-dropdown-item>

        <b-dropdown-item
          v-if="
            [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(get(getLoggedInUser, 'role')) &&
              getIsFacetsLinked
          "
          @click.prevent="() => fetchFacetsUrl()"
          target="_blank"
          exact
        >
          <md-icon>open_in_new</md-icon> FACETS Account
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown no-caret v-if="[USER_ROLES.STUDENT].includes(get(getLoggedInUser, 'role'))">
        <template slot="button-content">
          <div class="d-flex align-items-center" style="min-width: 250px;">
            <md-icon>apps</md-icon>
            <b-img
              :src="
                getLoggedInUserDashboard === 'carecampus'
                  ? '/images/logos/logo_icon.png'
                  : get(this.getLoggedInUser, 'linked_entity.logo_url')
              "
              width="40"
              height="40"
              class="mx-1"
              style="border-radius: 20px"
            />
            {{
              getLoggedInUserDashboard === 'carecampus' ? 'CareCampus' : get(this.getLoggedInUser, 'linked_entity.name')
            }}
          </div>
        </template>

        <b-dropdown-header tag="div" class="form-label"> Transition Scholar </b-dropdown-header>
        <b-dropdown-item
          @click.native.prevent="openDashboard(mainAppUrl)"
          style="min-width: 250px;"
          class="onhover"
          :style="getLoggedInUserDashboard === 'carecampus' ? 'background-color: lightgrey' : ''"
        >
          <b-img src="/images/logos/logo_icon.png" width="40" height="40" class="mr-2" style="border-radius: 20px" />
          CareCampus
        </b-dropdown-item>

        <b-dropdown-header v-if="getUserDashboards.grouped_entities.schools.length" tag="div" class="form-label">
          Schools
        </b-dropdown-header>

        <!-- v-if="get(getLoggedInUser, 'linked_entity.id')  !== dashboard.id" -->
        <div v-for="dashboard in getUserDashboards.grouped_entities.schools" :key="dashboard.slug">
          <b-dropdown-item
            @click.native.prevent="openDashboard(dashboard.subdomain.subdomain_url)"
            style="min-width: 250px;"
            :style="get(getLoggedInUser, 'linked_entity.slug') == dashboard.slug ? 'background-color: lightgrey' : ''"
            class="onhover"
          >
            <b-img
              :src="dashboard.logo_url"
              width="40"
              height="40"
              :alt="dashboard.name"
              class="mr-2"
              style="border-radius: 20px"
            />
            {{ dashboard.name }}
          </b-dropdown-item>
        </div>

        <b-dropdown-header v-if="getUserDashboards.grouped_entities.organizations.length" tag="div" class="form-label">
          Organization
        </b-dropdown-header>

        <!-- v-for="dashboard in getUserDashboards.grouped_entities.schools" :key="dashboard.slug" -->
        <div v-for="dashboard in getUserDashboards.grouped_entities.organizations" :key="dashboard.slug">
          <b-dropdown-item
            @click.native.prevent="openDashboard(dashboard.subdomain.subdomain_url)"
            class="onhover"
            style="min-width: 250px;"
            :style="get(getLoggedInUser, 'linked_entity.slug') == dashboard.slug ? 'background-color: lightgrey' : ''"
          >
            <b-img
              :src="dashboard.logo_url"
              width="40"
              height="40"
              :alt="dashboard.name"
              class="mr-2"
              style="border-radius: 20px"
            />
            {{ dashboard.name }}
          </b-dropdown-item>
        </div>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Brand -->
    <b-navbar-brand
      :to="isRoutePublicSchlLanding ? { name: 'sch-public-home' } : routeTo(routes.home)"
      :class="navbarBrandClass"
    >
      <!-- <fmv-avatar :title="true" size="sm" no-link class="navbar-brand-icon mr-0 mr-lg-8pt">
        <<b-img :src="brandImage" class="img-fluid" width="40" :alt="navbarBrand" />
        <b-img src="@/assets/images/logos/logo_icon.png" class="img-fluid" width="10" :alt="navbarBrand" />
      </fmv-avatar> -->
      <b-img
        v-if="![USER_ROLES.STUDENT].includes(get(getLoggedInUser, 'role'))"
        :src="brandImage"
        class="navbar-brand-icon mr-0 mr-lg-8pt"
        height="50"
        style="max-width: 100px !important; object-fit: contain"
        :alt="navbarBrand"
      />

      <!-- <span
        v-if="
          (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ||
            get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN ||
            get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER) &&
          !isMobileScreen
        "
        class="ml-4"
        style="font-size: 16px"
      >
        <md-icon class="mb-1">open_in_new</md-icon>
        <a target="_blank" :href="externalUrl">HealthCare Enroll</a>
      </span> -->

      <!-- <span class="d-none d-lg-block" style="font-size: 25px" v-text="navbarBrand" /> -->
    </b-navbar-brand>

    <!-- v-if="
            (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER) &&
            isMobileScreen
          " -->

    <!-- <template v-if="!navbarFullSampleGuestMode && navbarStats && !isInstructor">
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>opacity</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100">Experience IQ</strong>
          <span class="navbar-text-50">2,300 points</span>
        </small>
      </span>
    </template> -->

    <!-- <template v-if="!navbarFullSampleGuestMode && navbarStats && isInstructor">
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>trending_up</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100" v-text="$t('Earnings')" />
          <span class="navbar-text-50">&dollar;12.3k</span>
        </small>
      </span>
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>receipt</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100" v-text="$t('Sales')" />
          <span class="navbar-text-50">264</span>
        </small>
      </span>
    </template> -->
    <template v-if="isRoutePublicSchlLanding">
      <b-navbar-nav class="d-none d-sm-flex flex justify-content-end mr-5">
        <!-- School's website link for public pages. -->
        <!-- <li class="nav-item" v-if="get(getCurrentSchool, 'website_url')">
          <a :href="getClickableLink(get(getCurrentSchool, 'website_url'))" target="_blank" class="nav-link">Home</a>
        </li> -->

        <!-- School's public pages. -->
        <b-nav-item :to="{ name: 'sch-public-home' }" exact>For Students</b-nav-item>
        <b-nav-item :to="{ name: 'sch-public-parnerships' }">For Employers</b-nav-item>
        <!-- <b-nav-item :to="{ name: 'sch-public-parnerships' }">Partner With Us</b-nav-item> -->
      </b-navbar-nav>
    </template>

    <!-- Navbar routes for student layout. -->
    <template v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT">
      <!-- TODO: Show these routes to unauthenticated user. -->

      <b-navbar-nav class="d-none d-sm-flex flex justify-content-end mr-5">
        <b-nav-item :to="{ name: 'student-dashboard' }">Home</b-nav-item>

        <b-nav-item :to="{ name: 'student-info' }">My Info</b-nav-item>
        <b-nav-item
          v-if="![DASHBOARD_TYPES.SCHOOL].includes(this.getLoggedInUserDashboard)"
          :to="{ name: 'student-advisors' }"
          >Advisors</b-nav-item
        >
        <b-nav-item v-if="getLoggedInUserDashboard === DASHBOARD_TYPES.ORGANIZATION" :to="{ name: 'student-community' }"
          >Resources</b-nav-item
        >

        <!-- <b-nav-item :to="{ name: 'student-questions' }" v-if="getPaymentStatus">Questions</b-nav-item>
        <b-nav-item :to="{ name: 'student-skill' }" v-if="getPaymentStatus">Skills</b-nav-item> -->

        <!-- <b-nav-item-dropdown right> -->
        <!-- <template slot="button-content"> Careers </template> -->

        <!-- <b-dropdown-item :to="{ name: 'student-jobs-listing' }" exact>All Jobs </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'student-local-jobs-listing' }" exact>Featured Jobs </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'student-employers-list' }">Companies</b-dropdown-item> -->

        <!-- <b-dropdown-item :to="{ name: 'student-external-jobs-listing' }" exact>External Jobs </b-dropdown-item> -->
        <!-- </b-nav-item-dropdown> -->
        <!-- <b-nav-item :to="{ name: 'student-scholarships-listing' }">Sponsorships</b-nav-item> -->
        <!-- <b-nav-item :to="{ name: 'student-employers-list' }">Companies</b-nav-item> -->

        <!-- <b-nav-item-dropdown right v-if="get(getLoggedInUser, 'linked_entity.std_portal_uni')">
          <template slot="button-content">Pathways</template>
          <b-dropdown-item :to="{ name: 'student-org-listing' }" exact>Organizations</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'student-uni-listing' }" exact>Universities </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{ name: 'student-org-listing' }" v-else>Pathways</b-nav-item> -->

        <!-- <b-nav-item :to="{ name: 'student-community' }">Resources</b-nav-item> -->
        <!-- TODO: Uncomment after interviews integration. -->
        <!-- <b-nav-item :to="{ name: 'std-manage-resume' }">Interviews</b-nav-item> -->

        <!-- TODO: Careers and events temporarily disabled for students. -->
        <!-- <b-nav-item :to="{ name: 'student-careers' }" exact>Careers</b-nav-item>
        <b-nav-item :to="{ name: 'student-events' }">Events</b-nav-item> -->

        <!-- <b-nav-item :to="{ name: 'student-home' }" exact v-b-tooltip.hover.bottom="'Coming Soon!'" disabled
          >Events</b-nav-item
        > -->
        <!-- Sample Dropdown style links -->
        <!-- <b-nav-item-dropdown :text="$t('Courses')" no-caret>
          <b-dropdown-item :to="routeTo(routes.studentCourses)">{{ $t('Browse Courses') }}</b-dropdown-item>
          <b-dropdown-item :to="routeTo(routes.studentTakeCourse)">
            <span class="mr-16pt">{{ $t('Take Course') }}</span>
            <b-badge variant="accent badge-notifications" class="text-uppercase ml-auto" v-text="$t('Pro')" />
          </b-dropdown-item>
          <b-dropdown-item :to="routeTo(routes.studentTakeLesson)">{{ $t('Take Lesson') }}</b-dropdown-item>
        </b-nav-item-dropdown> -->
      </b-navbar-nav>
    </template>

    <template v-if="navbarSearch">
      <!-- Search -->
      <b-form class="search-form navbar-search d-none mr-16pt d-md-flex">
        <!-- :class="{
          'd-lg-flex': navbarFullSampleGuestMode,
          'd-md-flex': !navbarFullSampleGuestMode,
        }"
      > -->
        <b-button variant="none">
          <md-icon>search</md-icon>
        </b-button>
        <b-form-input placeholder="Search" type="text" />
      </b-form>
      <!-- // END Search -->
    </template>

    <!-- Menu -->
    <b-navbar-nav class="flex-nowrap d-flex ml-auto">
      <template v-if="isRoutePublicSchlLanding">
        <li class="nav-item">
          <!-- <a href="https://app.jointransition.com/" :class="`btn btn-${ctaClass}`"
            ><md-icon class="mr-1">lock_open</md-icon>Login</a
          > -->
          <b-btn :to="{ name: 'login' }" :variant="ctaClass"><md-icon class="mr-1">lock_open</md-icon> Login</b-btn>
        </li>
      </template>

      <template v-else>
        <!-- Wallet -->
        <nav-wallet
          icon="account_balance_wallet"
          icon-class="icon-24pt"
          title="Wallet"
          v-if="
            get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN ||
              get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION
          "
        />
        <nav-scl-wallet
          icon="account_balance_wallet"
          icon-class="icon-24pt"
          title="Wallet"
          v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"
        />
        <nav-std-wallet
          icon="account_balance_wallet"
          icon-class="icon-24pt"
          title="Wallet"
          v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT"
        />

        <!-- Messages -->
        <!-- <navbar-notifications
          icon="mail_outline"
          icon-class="icon-24pt"
          title="Messages"
          :notifications="messages"
          :route-messages="routes.messages"
        /> -->

        <!-- Notifications -->
        <navbar-notifications
          :badge="true"
          title="navbarMsgs.notifications"
          :route-messages="routes.messages"
          v-if="
            [
              USER_ROLE_TYPES.SCHOOL,
              USER_ROLE_TYPES.EMPLOYER,
              USER_ROLE_TYPES.STUDENT,
              USER_ROLE_TYPES.ORGANIZATION,
              USER_ROLE_TYPES.SUPER_ADMIN,
            ].includes(get(getLoggedInUser, 'role_type'))
          "
        />

        <b-nav-item-dropdown
          right
          no-caret
          v-if="
            get(getLoggedInUser, 'role') !== USER_ROLES.PARTNER && get(getLoggedInUser, 'role') !== USER_ROLES.STUDENT
          "
        >
          <template slot="button-content">
            <md-icon>settings</md-icon>
          </template>
          <b-dropdown-header tag="div" class="form-label"> Settings </b-dropdown-header>
          <b-dropdown-item
            :to="{ name: 'manage-school-profile' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
          >
            <md-icon>business</md-icon> School Profile
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'manage-student-dashboard' }"
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
          >
            <md-icon>dashboard</md-icon> Student Dashboard
          </b-dropdown-item>

          <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="my-1"
          />

          <b-dropdown-item
            :to="{ name: 'manage-users' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
          >
            <md-icon>person</md-icon> Users
          </b-dropdown-item>

          <!-- Manage Partnerships (for School & Employer Admins ) -->
          <!-- <b-dropdown-item
            :to="{ name: 'manage-partnerships' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
          >
            <i class="fas fa-handshake mr-1"></i>Partnerships
          </b-dropdown-item> -->

          <b-dropdown-item
            :to="{ name: 'manage-student-invite-list' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
          >
            <md-icon>drafts</md-icon> Invites
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'manage-student-signup-list' }"
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
                get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION
            "
          >
            <md-icon>person_add</md-icon> Sign-up Requests
          </b-dropdown-item>

          <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="my-1"
          />

          <b-dropdown-item
            :to="{ name: 'configure-student-docs' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
          >
            <md-icon>insert_drive_file</md-icon> Document Configuration
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'manage-skill-config' }"
            v-if="
              [USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role')) &&
                get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN
            "
          >
            <md-icon>videocam</md-icon> Skills Configuration
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'my-questions' }"
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
          >
            <md-icon>question_answer</md-icon> My Questions
          </b-dropdown-item>

          <b-dropdown-item
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            :to="{ name: 'school-manage-resources' }"
          >
            <md-icon>folder</md-icon> Resources
          </b-dropdown-item>

          <!-- <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="mb-2"
          /> -->
          <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="my-1"
          />

          <b-dropdown-item
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            :to="{ name: 'school-integrations' }"
          >
            <md-icon>device_hub</md-icon> Integrations
          </b-dropdown-item>

          <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="my-1"
          />

          <b-dropdown-item
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            :to="{ name: 'feature-updates-page' }"
          >
            <md-icon>lightbulb</md-icon> Feature Updates
          </b-dropdown-item>

          <!-- <div v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT"> -->
          <!-- <b-dropdown-divider class="mb-2" /> -->

          <!-- <b-dropdown-item
              :to="{
                name: get(getStoryExists, 'story_existed')
                  ? 'update-sponsorship-spotlight'
                  : 'student-sponsorship-spotlight',
              }"
            >
              <md-icon>account_balance_wallet</md-icon> Sponsorship Spotlight
            </b-dropdown-item>

            <b-dropdown-divider class="my-1" /> -->

          <!-- <b-dropdown-item :to="{ name: 'std-manage-records' }" class="mt-1">
              <md-icon>drive_folder_upload</md-icon> My Uploads
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'std-payment-list' }">
              <md-icon>account_balance_wallet</md-icon> My Payments
            </b-dropdown-item>

            <b-dropdown-divider class="my-1" />

            <b-dropdown-item :to="{ name: 'std-manage-resume' }">
              <md-icon>description</md-icon> My Resume
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'std-manage-applications' }">
              <md-icon>assignment</md-icon> My Applications
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'my-questions' }" v-if="getPaymentStatus">
              <md-icon>help</md-icon> My Questions
            </b-dropdown-item>

            <b-dropdown-divider v-if="getPaymentStatus" class="my-1" />

            <b-dropdown-item :to="{ name: 'my-skills' }" v-if="getPaymentStatus">
              <md-icon>videocam</md-icon> My Skills
            </b-dropdown-item>

            <b-dropdown-item v-if="getInAppPaymentStatus" :to="{ name: 'manage-plans' }">
              <md-icon>attach_money</md-icon> My Plans
            </b-dropdown-item> -->

          <!-- <b-dropdown-item :to="{ name: 'student-community' }"> <md-icon>folder</md-icon> Resources </b-dropdown-item> -->
          <!-- <b-dropdown-divider v-if="getInAppPaymentStatus" class="mb-2" /> -->

          <!-- <b-dropdown-item v-if="getInAppPaymentStatus" :to="{ name: 'manage-payment-method' }">
              <md-icon>payment</md-icon> Billing
            </b-dropdown-item> -->
          <!-- </div> -->
          <b-dropdown-item
            :to="{ name: 'system-config' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN"
          >
            <md-icon>build</md-icon> System Config
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'employer-setup-guide' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
          >
            <md-icon>build</md-icon> Setup Guide
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'manage-company-profile' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
          >
            <md-icon>business</md-icon> Company Profile
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'manage-payment-method' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
          >
            <md-icon>payment</md-icon> Billing
          </b-dropdown-item>

          <div v-if="get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION">
            <b-dropdown-item :to="{ name: 'manage-org-profile' }" class="mt-1">
              <md-icon>business</md-icon> Org Profile
            </b-dropdown-item>
          </div>
          <div v-if="get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION">
            <b-dropdown-item :to="{ name: 'org-setup-guide' }" class="mt-1">
              <md-icon>build</md-icon> Setup Guide
            </b-dropdown-item>
          </div>

          <template
            v-if="
              [USER_ROLES.SUPER_ADMIN, USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(
                get(getLoggedInUser, 'role'),
              ) && isMediumScreen
            "
          >
            <b-dropdown-divider class="mb-1" />

            <b-dropdown-item :href="externalUrl" target="_blank">
              <md-icon>open_in_new</md-icon> Transition Enroll
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                [USER_ROLES.SCHOOL_ADMIN, USER_ROLES.SCHOOL_OWNER].includes(get(getLoggedInUser, 'role')) &&
                  getIsFacetsLinked
              "
              @click.prevent="() => fetchFacetsUrl()"
              target="_blank"
            >
              <md-icon>open_in_new</md-icon> Facets Account
            </b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
        <!-- Localization -->
        <!-- <i18n-dropdown navbar :locales="localesOptions" /> -->

        <!-- User Dropdown -->
        <b-nav-item-dropdown right>
          <template slot="button-content">
            <user-avatar :user="getLoggedInUser" variant="dark"> </user-avatar>
          </template>
          <b-dropdown-header tag="div" class="form-label">
            {{ $t('navbarMsgs.manage') }}
          </b-dropdown-header>

          <b-dropdown-item
            :to="{ name: 'student-my-profile' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT"
          >
            <md-icon>person</md-icon> {{ $t('profileMsgs.myProfile') }}
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'my-profile' }" v-else> <md-icon>person</md-icon> My Account </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'manage-intro-video' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT"
          >
            <md-icon>ondemand_video</md-icon> Intro Video
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'change-password' }"> <md-icon>security</md-icon> Security </b-dropdown-item>

          <!-- Employer admin's management option -->
          <!-- <b-dropdown-divider v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN" class="mb-2" /> -->

          <!-- <b-dropdown-item :to="{ name: 'hires' }" v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN">
            <md-icon>how_to_reg</md-icon> Hires
          </b-dropdown-item> -->

          <!-- School owner & admin's management option -->
          <!-- <b-dropdown-divider
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            class="mb-2"
          /> -->

          <!-- Student options -->

          <!-- <b-dropdown-divider class="mb-2" /> -->

          <b-dropdown-divider />
          <b-dropdown-item :to="routeTo(routes.logout || routes.login)">
            <md-icon>lock</md-icon> {{ $t('navbarMsgs.logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- // END User dropdown -->
      </template>
    </b-navbar-nav>
    <!-- // END Menu -->
    <std-center-modal :show-modal="showStdModal" @close="hideStdModal" />
  </div>
</template>

<script>
import routeToMixin from '@/mixins/route-to';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { mapGetters, mapActions } from 'vuex';
import NavbarNotifications from './Notifications.vue';
import NavWallet from './NavWallet.vue';
import NavStdWallet from './NavStdWallet.vue';
import UserAvatar from '../User/UserAvatar.vue';
import { USER_ROLES, USER_ROLE_TYPES, SCH_PAYMENT_PLANS, DASHBOARD_TYPES } from '../../common/constants';
import brandImage from '@/assets/images/logos/logo.png';
import { get } from 'lodash';
import NavSclWallet from './NavSclWallet.vue';
import StdCenterModal from '../../views/SchoolPortal/StudentCenter/StdCenterModal.vue';
import { getClickableLink } from '@/common/utils';
import stdPointsToast from '@/mixins/std-points-toast';

export default {
  components: {
    NavbarNotifications,
    MdIcon,
    UserAvatar,
    NavWallet,
    NavSclWallet,
    StdCenterModal,
    NavStdWallet,
  },
  mixins: [routeToMixin, stdPointsToast],
  props: {
    navbarType: {
      type: String,
      default: () => 'light',
    },
    navbarContentContainerClass: {
      type: [String, Array, Object],
      default: () => 'd-flex w-100 align-items-center',
    },
    navbarFullSampleGuestMode: {
      type: Boolean,
    },
    navbarBrand: {
      type: String,
      default: () => 'Transition',
    },
    brandImage: {
      type: String,
      default() {
        return brandImage;
      },
    },
    navbarBrandClass: {
      type: [String, Object, Array],
      default: () => null,
    },
    navbarSearch: {
      type: Boolean,
      default: () => true,
    },
    navbarStats: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      USER_ROLES,
      USER_ROLE_TYPES,
      SCH_PAYMENT_PLANS,
      DASHBOARD_TYPES,
      localesOptions: [{ code: 'en', iso: 'en-US', file: 'en.json' }],
      isInstructor: null,
      windowWidth: window.innerWidth,
      showStdModal: false,
      messages: [
        {
          id: 1,
          created_at: this.$t('diff_for_humans', {
            when: this.$tc('datetime_units.min', 3, { value: 3 }),
          }),
          iconClass: 'text-danger',
          avatar: '/images/people/110/woman-5.jpg',
          name: 'Michelle',
          message: 'Clients loved the new design.',
          is_read: true,
        },
        {
          id: 2,
          created_at: this.$t('diff_for_humans', {
            when: this.$tc('datetime_units.hr', 5, { value: 5 }),
          }),
          iconClass: 'text-success',
          avatar: '/images/people/110/woman-5.jpg',
          name: 'Michelle',
          message: '🔥 Superb job..',
          is_read: false,
        },
      ],
      externalUrl: process.env.VUE_APP_HCENROLL_URL,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'isLoggedIn', 'getLoggedInUserDashboard', 'getUserDashboards']),
    ...mapGetters('school', ['getCurrentSchool', 'getPaymentStatus', 'getInAppPaymentStatus', 'getIsFacetsLinked']),
    ...mapGetters('stories', ['getStoryExists']),
    ...mapGetters('student', ['getShowEarnedPointToast']),

    isNavbarDark() {
      let some = [this.navbarType.indexOf('dark') !== -1, this.navbarType.indexOf('black') !== -1];
      return some.some(some => !!some);
    },
    ctaClass() {
      return this.isNavbarDark ? 'white' : 'secondary';
    },

    mainAppUrl() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },

    isRoutePublicSchlLanding() {
      return [
        'sch-public-home',
        'sch-public-parnerships',
        'sch-public-jobs',
        'sch-public-careers',
        'sch-public-resume',
        'sch-public-interviews',
        'sch-public-testimonials',
      ].includes(this.$route.name);
    },

    isMobileScreen() {
      return this.windowWidth <= 420;
    },
    isMediumScreen() {
      return this.windowWidth <= 992;
    },
  },
  methods: {
    ...mapActions('school', ['getSchoolPaymentStatus', 'getinAppPaymentPlan', 'getFacetsUrl', 'getFacetsVerification']),
    ...mapActions('stories', ['getStoriesVerification']),
    ...mapActions('auth', ['generateTemporaryToken']),
    ...mapActions('student', ['updateShowEarnedPointToast']),

    get,

    openStdModal() {
      this.showStdModal = true;
    },
    hideStdModal() {
      this.showStdModal = false;
    },
    setIsInstructor() {
      let path = '';
      try {
        path = window ? window.location.pathname : this.$route.path;
      } catch (e) {
        // Handle if needed
      }
      this.isInstructor = path.indexOf('instructor') !== -1;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async openDashboard(subdomain) {
      const url = getClickableLink(subdomain);
      try {
        const res = await this.generateTemporaryToken();
        setTimeout(() => {
          window.open(`${url}/auth/?token=${res.temporary_token}`, '_blank');
        });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: `Couldn't redirect to FACETS. Please try again.` });
        throw e;
      }
    },

    async fetchFacetsUrl() {
      try {
        const res = await this.getFacetsUrl();

        window.open(`${res.data.facets_url}`, '_blank');
      } catch (e) {
        // make danger error toast in case of error
        this.makeToast({ variant: 'danger', msg: `Couldn't redirect to FACETS. Please try again.` });
        throw e;
      }
    },

    async fetchFacetVerification() {
      try {
        await this.getFacetsVerification();
      } catch (e) {
        //
      }
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler: 'setIsInstructor',
    },

    getShowEarnedPointToast(value) {
      try {
        if (value) {
          this.makeStdPointToast(value);
          this.updateShowEarnedPointToast(0);
        }
      } catch (e) {
        this.updateShowEarnedPointToast(0);
      }
    },
  },
  beforeMount() {
    document.addEventListener('inertia:success', () => this.setIsInstructor());
  },
  async mounted() {
    if (this.isLoggedIn && [USER_ROLE_TYPES.SCHOOL].includes(get(this.getLoggedInUser, 'role_type'))) {
      this.fetchFacetVerification();
    }

    if (this.isLoggedIn && [USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))) {
      this.getStoriesVerification();
    }

    if (
      this.isLoggedIn &&
      ([USER_ROLE_TYPES.SCHOOL].includes(get(this.getLoggedInUser, 'role_type')) ||
        ([USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type')) &&
          this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL))
    ) {
      try {
        await this.getSchoolPaymentStatus();
        await this.getinAppPaymentPlan();
      } catch (e) {
        //
      }
    }

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() {
    document.removeEventListener('inertia:success', () => this.setIsInstructor());
  },
};
</script>

<style lang="scss">
.onhover:hover {
  background-color: lightgray;
}
</style>
