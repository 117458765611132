import axios from 'axios';

const state = {
  advisorFilters: {},
};

const getters = {
  getAdvisorFilters: state => state.advisorFilters,
};

const mutations = {
  updateAdvisorFilters: (state, updatedFilters) => {
    state.advisorFilters = { ...state.advisorFilters, ...updatedFilters };
  },
};

const actions = {
  async getAllAdvisors(_obj, params) {
    const res = await axios.get(`api/advisors/`, { params });
    return res.data;
  },

  async getAdvisor(_obj, id) {
    const res = await axios.get(`api/advisors/${id}/`);
    return res.data;
  },

  async createAdvisor(_obj, data) {
    return axios.post(`api/advisors/`, data);
  },

  async updateAdvisor(_obj, { id, data }) {
    return axios.patch(`api/advisors/${id}/`, data);
  },
  async deleteAdvisor(_obj, id) {
    return axios.delete(`api/advisors/${id}/`);
  },

  async signupAdvisorFromInvite(_obj, data) {
    return axios.post(`api/advisors/invite/`, data);
  },

  async getOrgAdvisorInviteId() {
    return (await axios.get(`api/organizations/advisor-invite-id/`)).data;
  },

  async updateAdvisorFilters({ commit }, { filters }) {
    commit('updateAdvisorFilters', filters);
  },
};

export const advisors = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
